<template>
    <div class="page-add-aftersale-upload-img">
        <div class="page-body">
            <div class="img-num-box box-item">
                <div class="img-num-box-title title-item">异常探头总数</div>
                <Field
                    v-model="num"
                    placeholder="请输入探头总数(小于1000)"
                    input-align="left"
                    max="1000"
                    type="number"
                />
            </div>

            <div class="upload-box box-item">
                <div class="img-list-box-title title-item">上传现场图片</div>
                <div class="img-list">
                    <Uploader v-model="fileList" :max-size="20000 * 200000" :after-read="afterRead" accept="image/*" :max-count="9"/>
                </div>
            </div>

            <div class="upload-box box-item">
                <div class="warning-title title-item" @click="isShow = true">故障类型  <span>{{tagItem.tagName || '请选择'}}</span><Icon name="arrow-down" /></div>
            </div>
        </div>
        <div class="page-footer">
            <div class="back-btn" @click="back">返回</div>
            <div :class="{'submit-btn': true, 'pass': isPass}" @click="submit">提交</div>
        </div>
        <ActionSheet v-model="isShow" :actions="tagList" cancel-text="取消" close-on-click-action @select="selectWarning"/>
    </div>
</template>

<script>
import { Toast, Field, Uploader, ImagePreview, Icon, ActionSheet } from "vant";
import { getYMD } from '@/utils/time'
import { compressImage } from '@/lib/compressImage'
export default {
    name: "RyAddUploadImgPage",
    components: {
        Field,
        Uploader,
        [ImagePreview.Component.name]: ImagePreview.Component,
        Icon,
        ActionSheet
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            num: '',
            fileList: [],
            tagList: [],
            isShow: false,
            tagItem: {}
        };
    },
    created() {
        const vm = this;
        // const { USER_NAME } = vm.$config.keys;
        // let name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
        const fType = vm.$strTool.filterFactoryType(uuid);
        if (fType != 'yingwo') {
            vm.$router.replace({
                path: "404"
            });
            return
        }
        vm.loadTag(true)
    },

    computed: {
        isPass() {
            return Number(this.num) < 1000 && this.fileList.length > 0 && this.tagItem.id
        }
    },

    methods: {
        initData() {
            this.num = '';
            this.fileList = []
            this.tagItem = {}
            this.isShow = false
        },
        loadList() {
            const vm = this;
            const params = {
                paeNo: vm.pageNo,
                pageSize: 20
            }
            vm.loading = true
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/list`,
                params,
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                vm.loading = false;
                res.data.scrapProbList.data.map(item => {
                    item.time = getYMD(item.uploadAt);
                })
                vm.list = vm.list.concat(res.data.scrapProbList.data);
                vm.isShowAll = res.data.scrapProbList.data.length < 20
            });
        },
        afterRead(file) {
            const vm = this
            file.status = 'uploading';
            file.message = '上传中...';
            compressImage(file.content, {
                width: 500,
                quality: 0.1
            }).then(res => {
                // vm.uploadImg(convertBase64UrlToBlob(res), file);
                vm.uploadImg(res, file);
            })
            // vm.uploadImg(file)
        },
        uploadImg(file, upFile) {
            const vm = this;
            // const f = new FormData();
            // f.append('file', file.file)
            // alert(JSON.stringify(file))
            vm.$http({
                type: "post",
                url: `${vm.$config.base.ADMIN_URL}javaApi/file/iot/qcImages?password=${vm.$config.base.password}`,
                // data: { file: f }
                data: {
                    encodeFile: file.split('base64,')[1],
                    ext: file.split(';base64')[0].split('image/')[1]
                }
            }).then(res => {
                upFile.url = res.data;
                upFile.status = 'succ';
                upFile.message = '';
            }).catch(err => {
                console.log(err);
            });
        },

        previewImg(index) {
            const vm = this
            ImagePreview({
                images: vm.info.images,
                startPosition: index
            });
        },

        loadTag(isResult) {
            const vm = this;
            const params = {}
            !isResult ? (params.type = 6) : (params.handlingType = 2)
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}${isResult ? 'fc/careInfo/tags' : 'returnFac/tags'}`,
                params
            }).then(res => {
                res.data.map(item => {
                    item.name = item.tagName
                })
                vm.tagList = res.data
            });
        },

        selectWarning(obj) {
            this.tagItem = obj
        },

        submit() {
            const vm = this
            if (!vm.isPass) {
                let err = ''
                !vm.tagItem.id && (err = '请选择故障类型')
                !vm.fileList.length && (err = '请上传图片')
                !(Number(vm.num) < 1000) && (err = '请输入数量(1000以下)')
                err &&  Toast(err)
                return
            }
            let images = []
            vm.fileList.map(item => {
                images.push(item.url)
            })
            vm.ttid = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            vm.$http({
                    type: "POST",
                    url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe`,
                    data: {
                        size: vm.num,
                        tagId: vm.tagItem.id,
                        images,
                        password: vm.$config.base.password
                    }
                }).then((res) => {
                    vm.ttid && vm.ttid.clear()
                    if (res.code != 1) {
                        Toast.fail(res.message || '创建失败')
                        return
                    }
                    Toast.success({
                        message: '创建成功'
                    })
                    vm.initData();
                    vm.$router.back()
                }).catch(err => {
                    vm.loading = false;
                    vm.ttid && vm.ttid.clear()
                    console.log(err)
                });
        },

        back() {
            this.$router.back()
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-add-aftersale-upload-img {
    width: 100%;
    height: 100%;
    background: @blank;
    position: relative;
    overflow-y: auto;
    color: @black;
    padding-bottom: 60px;
    .page-body {
        padding: 15px 20px;
        .box-item {
            box-shadow: 0px 1px 15px 5px rgba(229, 229, 229, 0.5);
            border-radius: 6px;
            border: 3px solid @blank;
            padding: 15px 10px 0;
            font-size: 0.16rem;
            color: #303030;
            margin-bottom: 10px;
            .van-cell {
                padding-left: 0;
            }

            .van-uploader__preview-image {
                width: 75px;
                height: 75px;
            }

            .img-list-box-title {
                padding-bottom: 10px;
            }
            .warning-title {
                position: relative;
                padding-bottom: 10px;
                display: flex;
                justify-content: space-between;
                padding-right: 20px;
                .van-icon {
                    position: absolute;
                    right: 0px;
                    font-size: 18px;
                }

                span {
                    color: @themeColor;
                    font-weight: bold;
                }
            }
        }
    }
    .page-footer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        line-height: 60px;
        background: @blank;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 0.18rem;
        .back-btn {
            width: 200px;
            color: @themeColor;
        }
        .submit-btn {
            width: 100%;
            background: #D8D8D8;
            color: @blank;
            &.pass {
                background: @themeColor;
            }
        }
    }
}
</style>
